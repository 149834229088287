export const maskCurrency = (money) => {
    if (money) {
        // return parseFloat(money).toFixed(2)
        return parseFloat(money).toLocaleString("pt-BR", {
            style: "currency",
            currency: "EUR",
        })
    } else {
        return "0.00"
    }
}
