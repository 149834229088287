import React from 'react'
import Template from '../../components/template'
import { Row, Col, PageHeader, Table, Tag, Button, message, Modal, Form, Input, Divider, Spin, Popconfirm } from 'antd'
import { FormOutlined, MinusOutlined, PlusOutlined, SaveOutlined, FilePdfOutlined } from '@ant-design/icons'
import moment from "moment"
import { saveAs } from "file-saver"
import config from "../../config/config"

const Clientes = () => {

    const [cliente, setCliente] = React.useState([])
    const [loja, setLoja] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [modalCreate, setModalCreate] = React.useState(false)
    const [form] = Form.useForm()

    const createAndDownloadPdf = async (dados) => {
        const _initToken = await localStorage.getItem("authUserOptica")
        fetch(config[process.env.NODE_ENV].url + "/seguro/pdf", {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
            body: JSON.stringify(dados),
        }).then(() => {
            fetch(config[process.env.NODE_ENV].url + "/seguro/fetch-pdf", {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "x-access-token": _initToken,
                }),
                body: JSON.stringify(dados),
            }).then((res) => {
                if (res.ok) {
                    return res.blob().then((myBlob) => {
                        saveAs(myBlob, "generatedDocument.pdf")
                    })
                }
            })
        })
    }

    const newRegister = async (values) => {
        setLoading(true)
        const _initToken = await localStorage.getItem("authUserOptica")
        fetch(config[process.env.NODE_ENV].url + `/cliente/update/` + loja.id, {
            method: `PUT`,
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
            body: JSON.stringify(values),
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message)
                    setLoading(false)
                } else {
                    message
                        .success("Dados enviado com sucesso!", 3)
                        .then(() => {
                            setLoading(false)
                            loadClientes() //Atualizando tabela
                            setModalCreate(false) //fechando modal create

                        })
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
                message.error("Servidor está fora")
            })
    }

    const lojaSelected = (values) => {
        setLoja(values)
        setModalCreate(true)
        form.setFieldsValue(values)
        setLoading(false)
    }

    const deleteLoja = async (loja) => {

        setLoading(true)
        const _initToken = await localStorage.getItem("authUserOptica")
        fetch(config[process.env.NODE_ENV].url + `/loja/delete/${loja.id}`, {
            method: `DELETE`,
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            })
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message)
                    setLoading(false)
                } else {
                    message
                        .success("Loja removida com sucesso", 3)
                        .then(() => {
                            setLoading(false)
                            loadClientes() //Atualizando tabela
                        })
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
                message.error("Servidor está fora")
            })

    }

    const columns = [
        {
            title: "N#",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Cliente",
            dataIndex: "nome",
            key: "nome",
            sorter: (a, b) => a.nome.length - b.nome.length,
        },

        {
            title: "Loja",
            dataIndex: "cooperador",
            key: "cooperador",
            sorter: (a, b) => a.cooperador.length - b.cooperador.length,
        },

        {
            title: "Localidade",
            dataIndex: "localidade",
            key: "localidade",
            sorter: (a, b) => a.localidade - b.localidade,
        },

        {
            title: "NIF",
            dataIndex: "nif",
            key: "nif",
            sorter: (a, b) => a.nif - b.nif,
        },

        {
            title: "TELEFONE",
            dataIndex: "telefone",
            key: "telefone",
            sorter: (a, b) => a.nif - b.nif,
        },

        // {
        //     title: "Certificado",
        //     dataIndex: "numero_fatura",
        //     key: "numero_fatura",
        //     render: (val, rec) => {
        //         return <Button icon={<FilePdfOutlined />} shape='circle' onClick={() => createAndDownloadPdf(rec)}></Button>
        //     }
        // },
        {
            title: "E-mail",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Ações",
            dataIndex: "created_at",
            key: "created_at",
            width: 110,
            render: (value, record) => {
                return (
                    <>
                        <Button
                            shape="circle"
                            icon={<FormOutlined />}
                            style={{ marginRight: '8px' }}
                            onClick={() => lojaSelected(record)}
                        />
                        <Popconfirm
                            title="Tem certeza que deseja deletar esta tarefa?"
                            okText="Sim"
                            cancelText="Não"
                            onConfirm={() => deleteLoja(record)}
                        >
                            <Button type="danger" shape="circle" icon={<MinusOutlined />} />
                        </Popconfirm>
                    </>
                );
            },
        },
    ];

    const loadClientes = async () => {
        setLoading(true);
        const _initToken = await localStorage.getItem("authUserOptica");
        fetch(config[process.env.NODE_ENV].url + "/seguros/clientes", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message);

                } else {
                    setCliente(data);

                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                message.error("Servidor está fora");
            });
    };


    React.useEffect(() => {
        loadClientes()
    }, [])




    return <Template>
        <Row gutter={0}>
            <Col>
                <PageHeader
                    className="site-page-header"
                    title="Cliente"
                    subTitle="lista"
                    style={{ paddingLeft: "0" }}
                />
            </Col>
        </Row>

        <Row >

        </Row>
        <Row gutter={[20, 20]}>
            {/* <Col span={24} style={{ textAlign: 'left' }}>
        <Button shape="round" icon={<PlusOutlined />} onClick={() => {
          setModalCreate(true)
          setLoja([])
          form.resetFields()
        }}>NOVO</Button>
      </Col> */}
            <Col span={24}>
                <Table
                    columns={columns}
                    dataSource={cliente}
                    size="small"
                    bordered
                    loading={loading}
                ></Table>
            </Col>
        </Row>

        <Modal
            title="Cliente"
            visible={modalCreate}
            onOk={() => setModalCreate(false)}
            onCancel={() => setModalCreate(false)}
            destroyOnClose={true}
            footer={false}
            destroyOnClose={true}
        >
            <Spin spinning={loading}>
                <Form form={form} layout="vertical" onFinish={newRegister}>
                    <Row gutter={[20, 10]}>

                        <Col span={12}>
                            <Form.Item
                                label="Nome Completo"
                                name="nome"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo Cargo obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="NIF"
                                name="nif"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo Cargo obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Telefone"
                                name="telefone"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo Cargo obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>



                        <Col span={12}>
                            <Form.Item
                                label="E-mail"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo Cargo obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        {/* <Col span={12}>
                            <Form.Item
                                label="N Fatura"
                                name="numero_fatura"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo Cargo obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col> */}
                        <Col span={24}>
                            <Form.Item
                                label="E-mail"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo Cargo obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item>
                                <Button type="primary" icon={<SaveOutlined />} loading={loading} htmlType="submit" block>
                                    Salvar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    </Template>

}


export default Clientes
