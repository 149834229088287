import React from 'react'
import Template from "../../components/template"
import { Row, Col, PageHeader, Upload, Table, Tag, Button, message, Modal, Form, Input, Divider, Spin, Popconfirm } from 'antd'
import { UploadOutlined, FormOutlined, MinusOutlined, PlusOutlined, SaveOutlined, FilePdfOutlined } from '@ant-design/icons'
import config from '../../config/config'

const Banner = () => {

  const [banner, setBanner] = React.useState({})

  const options = {
    name: 'imagem',
    action: config[process.env.NODE_ENV].url + '/banner/update',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`Arquivo enviado com sucesso!`);
        loadBanner() //Carregando de novo
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} Erro no envio do arquivo.`);
      }
    }
  }


  const loadBanner = async () => {
    const _initToken = await localStorage.getItem("authUserOptica");
    fetch(config[process.env.NODE_ENV].url + "/banner", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        "x-access-token": _initToken,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.hasOwnProperty("message")) {
          message.error(data.message);
        } else {
          setBanner(data.content);

        }
      })
      .catch((err) => {

        console.log(err);
        message.error("Servidor está fora");
      });
  }


  React.useEffect(() => {
    loadBanner();
  }, []);



  return (<Template>
    <Row gutter={[20, 20]}>
      <Col span={24}>
        <PageHeader
          className="site-page-header"
          title="Banner Publicidade"
          subTitle=""
          style={{ paddingLeft: "0" }}
        />
      </Col>
      <Col span={24}>
        {Object.keys(banner).length > 0 ?
          <img
            src={config[process.env.NODE_ENV].url + '/banners/' + banner.imagem}
            alt="alt-img"
            height="90"
          />
          :
          <img src="https://via.placeholder.com/900x90?text=SEM+IMAGE" alt="alt-img" />
        }

      </Col>
      <Col span={24}>
        <Upload {...options} maxCount={1}
        >
          <Button
            icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </Col>
    </Row>
  </Template>)
}

export default Banner