import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AuthProvider } from './provider/auth';
import { MenuProvider } from './provider/menu';

ReactDOM.render(
  <>
        <AuthProvider>
            <MenuProvider>
                <App />
            </MenuProvider>
        </AuthProvider>

  </>,
  document.getElementById('root'),
);
