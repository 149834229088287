import React from "react";
import { Layout, Breadcrumb, Col, Row, Menu, Dropdown } from "antd";
import Siderbar from "./sider";
import {
    CloudFilled,
    DownOutlined,
    UserOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
} from "@ant-design/icons";
import {CustomBreadCrumb} from '../../components/breadcrumb/index'

import jwt_decode from "jwt-decode";
import { Redirect } from "react-router-dom";
import { useAuth } from "../../provider/auth";
import { useMenu } from "../../provider/menu";
import "./header.scss";

const { Header, Content, Footer } = Layout;
const Template = (props) => {
    const [logout, setLogout] = React.useState(false);
    const { user, setUser } = useAuth();
    const { menub, setMenu } = useMenu();

    // const { user, setUser } = useAuth()

    const sairButton = () => {
        setLogout(true);
        localStorage.removeItem("authUserOptica");
    };

    const loadHeader = () => {
        let { usuario } = jwt_decode(localStorage.getItem("authUserOptica"));
        if (user === undefined) {
            setUser(usuario);
        }
        // if (Object.keys(usuario).length > 0) {
        //     console.log(usuario)
        // }
    };

    React.useEffect(() => {
        loadHeader();
        console.log(user);
    }, [user]);

    const menuSair = (
        <Menu>
            <Menu.Item key="0" onClick={() => sairButton()}>
                Sair
            </Menu.Item>
        </Menu>
    );

    return (
        <Layout>
            {logout == true && <Redirect to="/" />}
            <Siderbar />

            <Layout className="site-layout">
                <Header
                    className="site-layout-background"
                    style={{ padding: 0, marginBottom: "50px" }}
                >
                    <Row gutter={20}>
                        <Col span={12}>
                            <div className="button">
                                {React.createElement(
                                    menub.collapsed
                                        ? MenuUnfoldOutlined
                                        : MenuFoldOutlined,
                                    {
                                        className: "trigger",
                                        onClick: () => {
                                            setMenu({
                                                collapsed: !menub.collapsed,
                                            });
                                        },
                                    }
                                )}
                            </div>
                        </Col>
                        <Col span={11} style={{ textAlign: "right" }}>
                            <Dropdown overlay={menuSair}>
                                <a
                                    className="ant-dropdown-link"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    <UserOutlined />{" "}
                                    {user != "" ? user?.nome : ""}{" "}
                                    <DownOutlined />
                                </a>
                            </Dropdown>
                        </Col>
                    </Row>
                </Header>
                            {props.breadcrumb && (
                                 <CustomBreadCrumb
                data={props.breadcrumb}
            />
                            )}



                <Content style={{ padding: "0 50px" }}>
                    {/* <Content style={{ margin: "24px 16px 0", overflow: "initial" }}> */}

                    <div
                        className="site-layout-background"
                        style={{ padding: 24, textAlign: "center" }}
                    >
                        {props.children}
                    </div>
                </Content>
                <Footer style={{ textAlign: "center" }}>Dourados ©2021</Footer>
            </Layout>
        </Layout>
    );
};

export default Template;
