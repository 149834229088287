import React from "react"
import Template from "../../components/template"
import NumberFormat from "react-number-format"
import InputMoney from "../../components/inputMoneyMask"
import config from "../../config/config"
import { Form, Row, Col, Input, Button, PageHeader, message } from "antd"
import { Redirect } from "react-router-dom"

const locale = "en-us"

const Seguro = () => {
    const [form] = Form.useForm()
    const [redirect, setRedirect] = React.useState(false)
    const { TextArea } = Input
    const onFinish = async (values) => {
        const _initToken = await localStorage.getItem("authUserOptica")
        fetch(config[process.env.NODE_ENV].url + "/seguro/store", {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
            body: JSON.stringify(values),
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message)
                } else {
                    message
                        .success("Dados enviado com sucesso!", 3)
                        .then(() => {
                            setRedirect(true)
                        })
                }
            })
            .catch((err) => {
                console.log(err)
                message.error("Servidor está fora")
            })
    }
    const onReset = () => {
        form.resetFields()
    }

    const currencyFormatter = (selectedCurrOpt) => (value) => {
        return new Intl.NumberFormat(locale, {
            style: "currency",
            currency: selectedCurrOpt.split("::")[1],
        }).format(value)
    }
    return (
        <Template>
            {redirect && <Redirect to="/gerenciar" />}
            <PageHeader
                className="site-page-header"
                title="Novo Seguro"
                // breadcrumb={{ routes }}
                subTitle="formulário"
                style={{ paddingLeft: "0%" }}
            />
            ,
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item
                            name="nome"
                            label="Nome"
                            rules={[
                                {
                                    required: true,
                                    message: "Campo nome obrigatório",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="nif"
                            label="NIF"
                            rules={[
                                {
                                    required: true,
                                    message: "Campo NIF obrigatório",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="telefone"
                            label="Telefone"
                            rules={[
                                {
                                    required: true,
                                    message: "Campo Telefone obrigatório",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="email"
                            label="E-mail"
                            rules={[
                                {
                                    required: true,
                                    message: "Campo E-mail obrigatório",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="numero_fatura"
                            label="Número da Fatura Associada
"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Campo Número de Fatura Associada obrigatório",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="modelo_dsc"
                            label="Modelo / Descrição do Produto Segurado"
                            rules={[
                                {
                                    required: true,
                                    message: "Campo Modelo obrigatório",
                                },
                            ]}
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="valor_prd"
                            label="Valor do Produto"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Campo Valor do Produto obrigatório",
                                },
                            ]}
                        >
                            <InputMoney form={form} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="valor_sgr"
                            label="Valor do Seguro"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Campo Valor do Seguro obrigatório",
                                },
                            ]}
                        >
                            <InputMoney form={form} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item style={{ textAlign: "left" }}>
                            <Button type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Template>
    )
}

export default Seguro
