import React, { useEffect, useState, useForm } from "react"
import Template from "../../components/template"
import config from "../../config/config"
import moment from "moment"
import { saveAs } from "file-saver"
import {
    PageHeader,
    Table,
    message,
    Button,
    Modal,
    Input,
    Form,
    Upload,
    Tag,
} from "antd"
import { maskCurrency } from "../../components/utils"
import {
    DownloadOutlined,
    UploadOutlined,
    StarOutlined,
} from "@ant-design/icons"
const GerenciamentSeguro = () => {
    const [seguros, setSeguros] = useState([])
    const [seguro, setSeguro] = useState([])
    const [loading, setLoading] = useState(false)
    const [loaDownload, setLoaDownload] = useState(false)
    const [modalDownload, setModalDownload] = useState(false)
    const [modalUpload, setModalUpload] = useState(false)
    const _initToken = localStorage.getItem("authUserOptica")
    const [form] = Form.useForm()
    const columns = [
        {
            title: "N#",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Nome Cliente",
            dataIndex: "nome",
            key: "nome",
        },
        {
            title: "NIF",
            dataIndex: "nif",
            key: "nif",
        },
        // {
        //     title: "E-mail",
        //     dataIndex: "email",
        //     key: "email",
        // },

        // {
        //     title: "Telefone",
        //     dataIndex: "telefone",
        //     key: "telefone",
        // },

        {
            title: "Número da Fatura",
            dataIndex: "numero_fatura",
            key: "numero_fatura",
        },

        {
            title: "Valor do produto",
            dataIndex: "valor_prd",
            key: "valor_prd",
            render: (valor) => {
                return maskCurrency(valor)
            },
        },

        {
            title: "Valor do seguro",
            dataIndex: "valor_sgr",
            key: "valor_sgr",
            render: (valor) => {
                return maskCurrency(valor)
            },
        },
        {
            title: "Data de Expiração",
            dataIndex: "data_exp",
            key: "data_exp",
            render: (value) => {
                return moment(value).format("DD/MM/YYYY")
            },
        },

        {
            title: "Data de registro",
            dataIndex: "created_at",
            key: "created_at",
            render: (value) => {
                return moment(value).format("DD/MM/YYYY")
            },
        },

        {
            title: "Status Comprovativos",
            dataIndex: "status_comprovativos",
            key: "status_comprocativos",
            render: (v) => {
                switch (v) {
                    case 1:
                        return <Tag color="orange">Aguarando</Tag>
                        break
                    case 2:
                        return <Tag color="green">Aprovado</Tag>
                        break
                    case 1:
                        return <Tag color="red">Reprovado</Tag>
                        break
                }
            },
        },
        {
            title: "Ações",
            dataIndex: "created_at",
            key: "created_at",
            render: (value, record) => {
                return (
                    <>
                        <Button
                            size="small"
                            icon={<DownloadOutlined />}
                            style={{ marginRight: "10px" }}
                            onClick={() => showModalDownload(record)}
                        >
                            Certificado
                        </Button>

                        <Button
                            size="small"
                            icon={<UploadOutlined />}
                            onClick={() => showModalUpload(record)}
                        >
                            RGPD
                        </Button>
                    </>
                )
            },
        },
    ]

    // Show modal Upload Certificado
    const showModalUpload = (record) => {
        setModalUpload(true)
        setSeguro(record)
        console.log(seguro)
    }

    const showModalDownload = (record) => {
        console.log(record)
        setModalDownload(true)
        setSeguro(record)
    }

    const createAndDownloadPdf = (dados) => {
        console.log(dados)
        fetch(config[process.env.NODE_ENV].url + "/seguro/pdf", {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
            body: JSON.stringify(dados),
        }).then(() => {
            fetch(config[process.env.NODE_ENV].url + "/seguro/fetch-pdf", {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "x-access-token": _initToken,
                }),
                body: JSON.stringify(dados),
            }).then((res) => {
                if (res.ok) {
                    return res.blob().then((myBlob) => {
                        saveAs(myBlob, "generatedDocument.pdf")
                    })
                }
            })
        })
    }

    const loadDataSeguros = async () => {
        setLoading(true)
        fetch(config[process.env.NODE_ENV].url + "/seguro", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message)
                } else {
                    setSeguros(data.content)
                    setLoading(false)
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
                message.error("Servidor está fora")
            })
    }

    const uploadSeguroSend = async (values) => {
        let data = new FormData()
        data.append("arquivo_rgpd", values)
        data.append("id_seguro", seguro.id)

        console.log(data)
        fetch(config[process.env.NODE_ENV].url + "/seguro/upload-rgpd", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: data,
        })
    }

    useEffect(() => {
        loadDataSeguros()
    }, [])

    return (
        <Template>
            <PageHeader
                className="site-page-header"
                title="Gerenciar"
                // breadcrumb={{ routes }}
                subTitle="seguros"
                style={{ paddingLeft: "0%" }}
            />
            <Table
                dataSource={seguros}
                columns={columns}
                bordered
                loading={loading}
            />
            {/* MODAL CERTIFICADO */}
            <Modal
                title="Certificado Seguro"
                visible={modalDownload}
                destroyOnClose={true}
                onOk={() => setModalDownload(false)}
                onCancel={() => setModalDownload(false)}
            >
                <p>
                    Dowload do Certificado do Seguro{" "}
                    <Button
                        icon={<DownloadOutlined />}
                        onClick={() => createAndDownloadPdf(seguro)}
                    >
                        Donwload
                    </Button>
                </p>
                <p>
                    Enviar por E-mail{" "}
                    <Form layout="inline" form={form}>
                        <Form.Item>
                            <Input />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" loading>
                                Enviar
                            </Button>
                        </Form.Item>
                    </Form>
                </p>
            </Modal>

            <Modal
                visible={modalUpload}
                title="Certificado RGPD"
                onCancel={() => setModalUpload(false)}
                onOk={() => setModalUpload(false)}
                footer={false}
                destroyOnClose={true}
            >
                <p>Upload Certificado RGPD: # {seguro.id}</p>
                <Form>
                    <Form.Item name="arquivo_rgpd">
                        <Upload
                            name="logo"
                            listType="picture"
                            action={uploadSeguroSend}
                            maxCount={1}
                            progress={{
                                strokeColor: {
                                    "0%": "#108ee9",
                                    "100%": "#87d068",
                                },
                                strokeWidth: 3,
                                format: (percent) =>
                                    `${parseFloat(percent.toFixed(2))}%`,
                            }}
                            defaultFileList={[
                                {
                                    uid: "1",
                                    name: seguro.arquivo_rgpd,
                                    status: "done",
                                    response: "Server Error 500", // custom error message to show
                                    url:
                                        config[process.env.NODE_ENV].url +
                                        "/seguro/download/" +
                                        seguro.arquivo_rgpd,
                                },
                            ]}
                            showUploadList={{
                                showDownloadIcon: false,
                                downloadIcon: "download",
                            }}
                        >
                            <Button icon={<UploadOutlined />}>
                                Click to upload
                            </Button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>
        </Template>
    )
}

export default GerenciamentSeguro
