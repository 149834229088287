import React, { useState } from "react";
import { Form, Input, Button, Checkbox, Row, Col, Card, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import logo from "../../images/logo.png";
import config from "../../config/config";
import { Redirect, withRouter } from "react-router";
import { useAuth } from "../../provider/auth";
import jwt_decode from "jwt-decode";
import './style.less'

const Login = (props) => {
    const { setUser } = useAuth();
    const [loading, setLoading] =   useState(false)

    const onFinish = (values) => {
        setLoading(true)
        fetch(config[process.env.NODE_ENV].url + "/administrador/auth/login", {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(values),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message);
                    setLoading(false)
                } else {
                    let decodeJwt = jwt_decode(data.content);
                    setUser(decodeJwt.usuario);
                    localStorage.setItem(
                        "authUserOptica",
                        JSON.stringify(data.content)
                    );
                    props.history.push("/dashboard");
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log(err);
                message.error("Servidor está fora");
                setLoading(false)
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    return (
        <>
            <Row>
                <Col span={6} offset={9} >
                    <Card style={{ marginTop: "20%", borderRadius: "10px",boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.19)"}}>
                        <Row justify="center" align="middle" gutter={[0, 20]}>
                            <Col>
                                <img
                                    src={logo}
                                    alt="logo"
                                    style={{ width: "100%" }}
                                />
                            </Col>
                            <Col span={24} style={{textAlign: "center"}}>
                                <h3>ÁREA DO ADMINISTRADOR</h3>
                            </Col>
                            <Col span={24}>
                                <Form
                                    name="normal_login"
                                    className="login-form"
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                >
                                    <Form.Item
                                        name="login"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Por favor digite seu usuario!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            prefix={
                                                <UserOutlined className="site-form-item-icon" />
                                            }
                                            placeholder="Usuário"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="senha"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Por favor digite sua senha!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            prefix={
                                                <LockOutlined className="site-form-item-icon" />
                                            }
                                            type="password"
                                            placeholder="Senha"
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Form.Item
                                            name="remember"
                                            valuePropName="checked"
                                            noStyle
                                        >
                                            <Checkbox>
                                                Manter Conectado
                                            </Checkbox>
                                        </Form.Item>
                                    </Form.Item>

                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="login-form-button"
                                            block
                                            loading={loading}
                                        >
                                            Entrar
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default withRouter(Login);
