import React from "react";
import { Layout, Menu } from "antd";
import {
    AppstoreOutlined,
    BarChartOutlined,
    CloudOutlined,
    ShopOutlined,
    ClusterOutlined,
    TeamOutlined,
    UserOutlined,
    UploadOutlined,
    ApartmentOutlined,
    FileImageOutlined,
    MonitorOutlined,
    DollarOutlined,
    ContainerOutlined,
} from "@ant-design/icons";
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import { useMenu } from "../../provider/menu";

const { Sider } = Layout;
const { SubMenu } = Menu;
const Siderbar = (props) => {
    const [select, setSelect] = React.useState("0");
    const [collapsed, setCollapsed] = React.useState(false);
    const { menub, setMenu } = useMenu();
    return (
        <Sider
            trigger={null}
            collapsible
            collapsed={menub.collapsed}
            style={{
                // overflow: "auto",
                height: "100vh",
                // position: "fixed",
                // left: 0,
            }}
        >
            <div className="brand">
                {/* <img
                    src={logo}
                    className="logo"
                    style={{ marginBottom: "20px" }}
                /> */}
            </div>
            <Menu
                mode="inline"
                selectedKeys={menub.actived}
                openKeys={menub.submenu}
            >
                <Menu.Item
                    key="0"
                    icon={<ContainerOutlined />}
                    onClick={() => setMenu({ actived: ["0"] })}
                >
                    <Link to={"/dashboard"}>Dashboard</Link>
                </Menu.Item>

                <Menu.Item
                    key="1"
                    icon={<ApartmentOutlined />}
                    onClick={() => setMenu({ actived: ["1"] })}
                >
                    <Link to={"/lojas"}>Lojas (revendas)</Link>
                </Menu.Item>

                <Menu.Item
                    key="2"
                    icon={<TeamOutlined />}
                    onClick={() => setMenu({ actived: ["2"] })}
                >
                    <Link to={"/clientes"}>Clientes</Link>
                </Menu.Item>

                <Menu.Item
                    key="3"
                    icon={<DollarOutlined />}
                    onClick={() => setMenu({ actived: ["3"] })}
                >
                    <Link to={"/pagamentos"}>Pagamentos</Link>
                </Menu.Item>

                <Menu.Item
                    key="4"
                    icon={<ClusterOutlined />}
                    onClick={() => setMenu({ actived: ["4"] })}
                >
                    <Link to={"/modalidades"}>Modalidades de seguro</Link>
                </Menu.Item>

                <Menu.Item
                    key="5"
                    icon={<MonitorOutlined />}
                    onClick={() => setMenu({ actived: ["5"] })}
                >
                    <Link to={"/sinistro"}>Sinistro</Link>
                </Menu.Item>

                <Menu.Item
                    key="6"
                    icon={<FileImageOutlined />}
                    onClick={() => setMenu({ actived: ["6"] })}
                >
                    <Link to={"/banner"}>Banner</Link>
                </Menu.Item>

                <Menu.Item
                    key="7"
                    icon={<UserOutlined />}
                    onClick={() => setMenu({ actived: ["7"] })}
                >
                    <Link to={"/usuarios"}>Usuários</Link>
                </Menu.Item>
            </Menu>
        </Sider>
    );
};

export default Siderbar;
