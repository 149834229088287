import React from "react";
import Template from "../../components/template";
import {
    Row,
    Col,
    PageHeader,
    Table,
    Tag,
    Button,
    message,
    Modal,
    Form,
    Input,
    Divider,
    Spin,
    Popconfirm,
    Upload,
    Space,
    Popover
} from "antd";
import {
    FormOutlined,
    MinusOutlined,
    PlusOutlined,
    SaveOutlined,
    SearchOutlined,
    UploadOutlined,
    FileExcelOutlined,
    KeyOutlined

} from "@ant-design/icons";
import config from "../../config/config";
import Highlighter from "react-highlight-words";

const Lojas = () => {
    const [cliente, setCliente] = React.useState([]);
    const [loja, setLoja] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [modalCreate, setModalCreate] = React.useState(false);
    const [form] = Form.useForm();
    const [searchText, setSearchText] = React.useState("");
    const [searchedColumn, setSearchedColumn] = React.useState("");
    const searchInput = React.useRef();

    // SEARCH TABLE
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Busca`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="mini"
                    onClick={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Procurar
                </Button>
                <Button
                    type="mini"
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Limpar
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? "#1890ff" : undefined }}
            />
        ),
        onFilter: (value, record) => {
            return record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current.select());
            }
        },
        render: (text) => {
            return searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            );
        },
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };


    const propsUpload = {
        name: 'file',
        method: 'post',
        action: config[process.env.NODE_ENV].url + `/lojas/excel`,
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const newRegister = async (values) => {
        setLoading(true);
        const _initToken = await localStorage.getItem("authUserOptica");
        fetch(
            config[process.env.NODE_ENV].url +
            `/lojas/${Object.keys(loja).length > 0 ? "update/" + loja.id : "store"
            }`,
            {
                method: `${Object.keys(loja).length ? "PUT" : "POST"}`,
                headers: new Headers({
                    "Content-Type": "application/json",
                    "x-access-token": _initToken,
                }),
                body: JSON.stringify(values),
            }
        )
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message);
                    setLoading(false);
                } else {
                    message
                        .success("Dados enviado com sucesso!", 3)
                        .then(() => {
                            loadLojas(); //Atualizando tabela
                            setModalCreate(false); //fechando modal create
                            setLoading(false);
                        });
                }
            })
            .catch((err) => {
                console.log(err);
                message.error("Servidor está fora");
                setLoading(false);
            });
    };

    const lojaSelected = (values) => {

        setLoja(values);
        setModalCreate(true);
        delete values.senha //remover senha
        form.setFieldsValue(values);
        setLoading(false);
    };

    const deleteLoja = async (loja) => {
        setLoading(true);
        const _initToken = await localStorage.getItem("authUserOptica");
        fetch(config[process.env.NODE_ENV].url + `/lojas/delete/${loja.id}`, {
            method: `DELETE`,
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message);
                    setLoading(false);
                } else {
                    message.success("Loja removida com sucesso", 3).then(() => {
                        setLoading(false);
                        loadLojas(); //Atualizando tabela
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                message.error("Servidor está fora");
            });
    };

    const sendPass = async (record) => {
        setLoading(true);
        const _initToken = await localStorage.getItem("authUserOptica");
        fetch(config[process.env.NODE_ENV].url + `/lojas/dados`, {
            method: `POST`,
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
            body: JSON.stringify({ id: record.id }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message);
                    setLoading(false);
                } else {
                    console.log(data)
                    message.success(`Dados de acesso enviado para ${data.accepted[0]}`, 4).then(() => {
                        setLoading(false);
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                message.error("Servidor está fora");
            });

    }

    const filterByString = <Input placeholder="Busca" value />;

    const columns = [
        {
            title: "N#",
            dataIndex: "id",
            key: "id",
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: "#PN",
            dataIndex: "codpn",
            key: "codpn",
            sorter: (a, b) => a.codpn - b.codpn,

        },
        {
            title: "Cooperador",
            dataIndex: "cooperador",
            key: "cooperador",
            sorter: (a, b) => a.cooperador.length - b.cooperador.length,
            ...getColumnSearchProps("cooperador"),
        },

        {
            title: "Pessoa Contacto",
            dataIndex: "contacto",
            key: "contacto",
            sorter: (a, b) => a.contacto.length - b.contacto.length,
            ...getColumnSearchProps("contacto"),
        },


        {
            title: "Localidade",
            dataIndex: "localidade",
            key: "localidade",
            sorter: (a, b) => a.localidade.length - b.localidade.length,
            ...getColumnSearchProps("localidade"),
        },
        {
            title: "E-mail/Login",
            dataIndex: "login",
            key: "login",
            sorter: (a, b) => a.login.length - b.login.length,
            ...getColumnSearchProps("login"),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (v) => {
                if (v) {
                    return <Tag color="#87d068">Ativo</Tag>;
                }

                if (!v) {
                    return <Tag color="#f50">Desativado</Tag>;
                }

                return "";
            },
        },
        {
            title: "Seg. da semana",
            dataIndex: "funcao",
            key: "funcao",
            sorter: (a, b) => a.funcao.length - b.funcao.length,
        },
        {
            title: "Seg. acumulados",
            dataIndex: "funcao",
            key: "funcao",
            sorter: (a, b) => a.funcao.length - b.funcao.length,
        },

        {
            title: "Valor em aberto",
            dataIndex: "funcao",
            key: "funcao",
            sorter: (a, b) => a.funcao.length - b.funcao.length,
        },
        {
            title: "Valor acumulado",
            dataIndex: "funcao",
            key: "funcao",
            sorter: (a, b) => a.funcao.length - b.funcao.length,
        },
        // {
        //     title: "Mod Seguro Disponíveis",
        //     dataIndex: "funcao",
        //     key: "funcao",
        //     sorter: (a, b) => a.funcao.length - b.funcao.length,
        // },
        {
            title: "Validar Comprovativos",
            dataIndex: "funcao",
            key: "funcao",
            sorter: (a, b) => a.funcao.length - b.funcao.length,
        },
        {
            title: "Valor de Comissão",
            dataIndex: "funcao",
            key: "funcao",
            sorter: (a, b) => a.funcao.length - b.funcao.length,
        },

        {
            title: "Ações",
            dataIndex: "created_at",
            key: "created_at",
            fixed: 'right',
            width: 140,
            render: (value, record) => {
                return (
                    <Space>
                        <Button
                            shape="circle"
                            icon={<FormOutlined />}
                            style={{ marginRight: "8px" }}
                            onClick={() => lojaSelected(record)}
                        />
                        <Popconfirm
                            title="Tem certeza que deseja deletar esta loja?"
                            okText="Sim"
                            cancelText="Não"
                            onConfirm={() => deleteLoja(record)}
                        >
                            <Button
                                type="danger"
                                shape="circle"
                                icon={<MinusOutlined />}
                            />
                        </Popconfirm>
                        <Popover content={`Enviar novos dados de acesso via E-mail`}>
                            <Button
                                shape="circle"
                                icon={<KeyOutlined />}
                                style={{ marginRight: "8px" }}
                                loading={loading}
                                onClick={() => sendPass(record)}
                            />
                        </Popover>

                    </Space>
                );
            },
        },
    ];

    const loadLojas = async () => {
        setLoading(true)
        const _initToken = await localStorage.getItem("authUserOptica");
        fetch(config[process.env.NODE_ENV].url + "/lojas", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message);
                    setLoading(false)
                } else {
                    setCliente(data.content);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                message.error("Servidor está fora");
                setLoading(false);
            });
    };

    React.useEffect(() => {
        loadLojas();
    }, []);

    return (
        <Template>
            <Row gutter={0}>
                <Col>
                    <PageHeader
                        className="site-page-header"
                        title="Lojas"
                        subTitle="lista"
                        style={{ paddingLeft: "0" }}
                    />
                </Col>
            </Row>

            <Row></Row>
            <Row gutter={[20, 20]}>
                <Col span={12} style={{ textAlign: "left" }}>
                    <Button
                        shape="round"
                        icon={<PlusOutlined />}
                        onClick={() => {
                            setModalCreate(true);
                            setLoja([]);
                            form.resetFields();
                        }}
                    >
                        NOVO
                    </Button>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                    <Upload {...propsUpload}>
                        <Button shape="round" icon={<FileExcelOutlined />}>Upload Excel</Button>
                    </Upload>
                </Col>
                <Col span={24}>
                    <Table
                        columns={columns}
                        dataSource={cliente}
                        size="small"
                        bordered
                        loading={loading}
                        pagination={{ position: ['topRight', 'bottomRight'] }}
                    ></Table>
                </Col>
            </Row>

            <Modal
                title={`${Object.keys(loja).length > 0
                    ? "Atualizar loja"
                    : "Nova Loja"
                    }`}
                visible={modalCreate}
                onOk={() => setModalCreate(false)}
                onCancel={() => setModalCreate(false)}
                destroyOnClose={true}
                footer={false}
            >
                <Spin spinning={loading}>
                    <Form form={form} layout="vertical" onFinish={newRegister}>
                        <Row gutter={[20, 10]}>
                            <Col span={24}>
                                <Form.Item
                                    label="Cooperador"
                                    name="cooperador"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Campo Cooperador obrigatório!",
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="Pessoa Contacto"
                                    name="contacto"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Campo Contacto obrigatório!",
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="Contribuinte"
                                    name="contribuinte"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Campo Contribuinte obrigatório!",
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="Telefone"
                                    name="telefone"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Campo Cargo obrigatório!",
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="Código Postal"
                                    name="codpostal"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Campo Código Postal obrigatório!",
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="Morada"
                                    name="morada"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Campo Morada obrigatório!",
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="Localidade"
                                    name="localidade"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Campo Localidade obrigatório!",
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>



                            <Divider>Dados de acesso</Divider>

                            <Col span={24}>
                                <Form.Item
                                    label="E-mail"
                                    name="login"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Campo Cargo obrigatório!",
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>


                            <Col span={12}>
                                <Form.Item
                                    label="Senha"
                                    name="senha"
                                    hasFeedback
                                    rules={[
                                        {
                                            required: Object.keys(loja).length > 0 ? false : true,
                                            message:
                                                "Campo Senha obrigatório!",
                                        },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="Repita a senha"
                                    name="senha_r"
                                    dependencies={["senha"]}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: Object.keys(loja).length > 0 ? false : true,
                                            message:
                                                "Campo Senha obrigatório!",
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (
                                                    !value ||
                                                    getFieldValue(
                                                        "senha"
                                                    ) === value
                                                ) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error(
                                                        "As duas senhas que você digitou não coincidem!!"
                                                    )
                                                );
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </Col>


                            <Col span={24}>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        icon={<SaveOutlined />}
                                        loading={loading}
                                        htmlType="submit"
                                        block
                                    >
                                        Salvar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </Template>
    );
};

export default Lojas;
