import React from "react";
import Template from "../../components/template";
import {
    Row,
    Col,
    PageHeader,
    Table,
    Tag,
    Button,
    message,
    Modal,
    Form,
    Input,
    Divider,
    Spin,
    Popconfirm,
    Select,
} from "antd";
import {
    DownloadOutlined,
    FormOutlined,
    MinusOutlined,
    PlusOutlined,
    SaveOutlined,
} from "@ant-design/icons";
import config from "../../config/config";
import InputMoney from "../../components/inputMoneyMask";

const Modalidades = () => {
    const [modalidade, setModalidade] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [selModalidade, setSelModalidade] = React.useState({});

    const [form] = Form.useForm();

    const { TextArea } = Input;

    const dataModalidades = async () => {
        const _initToken = await localStorage.getItem("authUserOptica");
        fetch(config[process.env.NODE_ENV].url + "/modalidade", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message);
                } else {
                    setModalidade(data.content);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                message.error("Servidor está fora");
            });
    };

    React.useEffect(() => {
        dataModalidades();
    }, []);

    const selected = (record) => {
        form.setFieldsValue(record);
        setShowModal(true);
    };

    const deleted = () => {};

    const onFinish = async (values) => {
        const _initToken = await localStorage.getItem("authUserOptica");
        fetch(config[process.env.NODE_ENV].url + "/modalidade/store", {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
            body: JSON.stringify(values),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message);
                } else {
                    // setUsuarios(data.content)
                    message
                        .success("Dados enviado com sucesso!", 3)
                        .then(() => {
                            setLoading(false);
                            dataModalidades();
                            setShowModal(false);
                            form.resetFields();
                        });
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                message.error("Servidor está fora");
            });
    };

    const columns = [
        {
            title: "N#",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "valor do item segurado",
            dataIndex: "valor_item",
            key: "valor_item",
        },

        {
            title: "valor do seguro",
            dataIndex: "valor_seguro",
            key: "valor_seguro",
        },

        {
            title: "Características seguro",
            dataIndex: "descricao",
            key: "descricao",
        },
        {
            title: "Ações",
            dataIndex: "created_at",
            key: "created_at",
            width: 110,
            render: (value, record) => {
                return (
                    <>
                        <Button
                            shape="circle"
                            icon={<FormOutlined />}
                            style={{ marginRight: "8px" }}
                            onClick={() => selected(record)}
                        />
                        <Popconfirm
                            title="Tem certeza que deseja deletar esta tarefa?"
                            okText="Sim"
                            cancelText="Não"
                            onConfirm={() => deleted(record)}
                        >
                            <Button
                                type="danger"
                                shape="circle"
                                icon={<MinusOutlined />}
                            />
                        </Popconfirm>
                    </>
                );
            },
        },
    ];

    return (
        <Template>
            <PageHeader
                className="site-page-header"
                title="Modalidades de seguro"
                // breadcrumb={{ routes }}
                subTitle="lista"
                style={{ paddingLeft: "0%" }}
            />

            <Row gutter={[20, 20]}>
                <Col span={24} style={{ textAlign: "left" }}>
                    <Button
                        icon={<PlusOutlined />}
                        shape="round"
                        onClick={() => setShowModal(true)}
                    >
                        Adicionar
                    </Button>
                </Col>
                <Col span={24}>
                    <Table
                        columns={columns}
                        dataSource={modalidade}
                        size="small"
                        bordered
                        loading={loading}
                        destroyOnClose={true}
                    />
                </Col>
            </Row>

            <Modal
                title="Modalidade"
                onCancel={() => setShowModal(false)}
                onOk={() => setShowModal(false)}
                visible={showModal}
                footer={false}
            >
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="Nome do seguro"
                                name="nome"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo obrigatório!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="Valor do item segurado"
                                name="valor_item"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo obrigatório!",
                                    },
                                ]}
                            >
                                <InputMoney form={form} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="Valor do seguro"
                                name="valor_seguro"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo obrigatório!",
                                    },
                                ]}
                            >
                                <InputMoney form={form} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="Características Gerais do Seguro"
                                name="descricao"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Campo características obrigatório!",
                                    },
                                ]}
                            >
                                <TextArea />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    icon={<SaveOutlined />}
                                    loading={loading}
                                    htmlType="submit"
                                    block
                                >
                                    Salvar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Template>
    );
};

export default Modalidades;
