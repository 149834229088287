import React, { useState, useEffect } from "react";
import Template from "../../components/template";
import { CustomBreadCrumb } from "../../components/breadcrumb/index";
import { Statistic, Card, Col, Row, PageHeader, message } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import config from "../../config/config"
const Dashboard = () => {
    const [loading, setLoading] = useState(true)
    const [dash, setDash] = useState([])

    const loadData = async() => {
         setLoading(true);
    const _initToken = await localStorage.getItem("authUserOptica");
    fetch(config[process.env.NODE_ENV].url + "/dashboard", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        "x-access-token": _initToken,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.hasOwnProperty("message")) {
          message.error(data.message);

        } else {
          setDash(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        message.error("Servidor está fora");
      });
    }

    useEffect(() => {
        loadData()
    }, [])


    return (
        <>

        <Template breadcrumb={['Dashboard']}>

            <Row gutter={0}>
                <Col>
                    <PageHeader
                        className="site-page-header"
                        title="Dashboard"
                        subTitle=""
                        style={{ paddingLeft: "0" }}
                    />
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Ultimos seguros incluídos"
                            value={dash.segurosIncluidos}
                            loading={loading}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Total Seguros da semana"
                            value={dash.segurosSemana}
                            loading={loading}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title="Total Lojas" value={dash.totalLojas} loading={loading} />
                    </Card>
                </Col>

                <Col span={6}>
                    <Card>
                        <Statistic title="Top 5 lojas em vendas" value={0} loading={loading}/>
                    </Card>
                </Col>

                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Top 5 lojas valores gerados"
                            prefix={"€"}
                            value={0}
                            precision={2}
                            loading={loading}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Valor à Transferir"
                            prefix={"€"}
                            value={0}
                            precision={2}
                            loading={loading}
                        />
                    </Card>
                </Col>
            </Row>
        </Template> </>
    );
};

export default Dashboard;
