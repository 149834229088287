import React from 'react'

export const MenuContext = React.createContext({})

export const MenuProvider = (props) => {
    const [menub, setMenu] = React.useState({actived: ["0"], submenu: [""], collapsed: false})
    return (
        <MenuContext.Provider value={{menub, setMenu}}>
            {props.children}
        </MenuContext.Provider>
    )
}


export const useMenu = () => React.useContext(MenuContext)
