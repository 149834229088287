import React from "react"
import Template from "../../components/template"
import {
    PageHeader,
    Row,
    Col,
    Button,
    Table,
    message,
    Modal,
    Form,
    Input,
    Checkbox,
    Upload,
    Tag,
    Spin,
    Card
} from "antd"
import {
    PlusSquareOutlined,
    UploadOutlined,
    DeleteOutlined,
    LoadingOutlined,
    CheckOutlined,
    SearchOutlined,
} from "@ant-design/icons"
import moment from "moment"
import config from "../../config/config"

const Sinistro = () => {
    const [loading, setLoading] = React.useState(false)
    const [showModalS, setShowModalS] = React.useState(false)
    const [loadSubmit, setLoadSubmit] = React.useState(false)
    const [showModal, setShowModal] = React.useState(false)
    const [sinistro, setSinistro] = React.useState([])
    const [selectRecord, setSelectRecord] = React.useState([])
    const [check, setCheck] = React.useState(false)
    const [foto, setFoto] = React.useState([])

    const _initToken = localStorage.getItem("authUserOptica")

    const [form] = Form.useForm()

    const { TextArea } = Input

    const closeModal = () => {
        setShowModal(false)
    }

    const onFinish = async (values) => {
        setLoadSubmit(true)

        let documentos = values.fotos.fileList.map((v) => v.response.filename)
        delete values.fotos
        values.documentos = documentos
        values.franquia = check
        const _initToken = await localStorage.getItem("authUserOptica")
        fetch(config[process.env.NODE_ENV].url + "/sinistro/store", {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
            body: JSON.stringify(values),
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    setLoadSubmit(false)
                    message.error(data.message)

                    setShowModal(false)
                } else {
                    message
                        .success("Dados enviado com sucesso!", 3)
                        .then(() => {
                            setLoadSubmit(false)
                            loadSinistro()
                            setShowModal(false)
                        })
                }
            })
            .catch((err) => {
                console.log(err)
                message.error("Servidor está fora")
                setLoadSubmit(false)
            })
    }

    const columns = [
        {
            title: "N#",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "N# seguro",
            dataIndex: "seguros_id",
            key: "seguros_id",
        },
        {
            title: "Descrição",
            dataIndex: "descricao",
            key: "descricao",
        },

        {
            title: "status",
            dataIndex: "status",
            key: "status",
            render: (record) => {
                let status = {
                    1: "Aguardando",
                    2: "Aprovado",
                    3: "Pagamento ao cliente",
                }
                return <Tag>{status[record]}</Tag>
            },
        },

        {
            title: "Data de registro",
            dataIndex: "created_at",
            key: "registro",
            render: (value) => {
                return moment(value).format("DD/MM/YYYY")
            },
        },

        {
            title: "Ações",
            dataIndex: "created_at",
            key: "created_at",
            render: (value, record) => {
                return (
                    <>
                        <Button
                            shape="circle"
                            size="small"
                            icon={<SearchOutlined />}
                            onClick={() => loadModal(record)}
                            style={{ marginRight: "10px" }}
                        />



                        {/* <Button
                            size="small"
                            shape='circle'
                            type='danger'
                            icon={<DeleteOutlined />}
                        /> */}
                    </>
                )
            },
        },
    ]

    const loadModal = async (dados) => {
        setSelectRecord(dados)
        loadFotos(dados.id)
        setShowModalS(true)
    }

    const loadSinistro = async () => {
        setLoading(true)
        fetch(config[process.env.NODE_ENV].url + "/sinistro", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message)
                } else {
                    setSinistro(data.content)
                    setLoading(false)
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
                message.error("Servidor está fora")
            })
    }

    const loadFotos = async (id) => {

        fetch(config[process.env.NODE_ENV].url + "/sinistros/fotos/" + id, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message)
                } else {
                    setFoto(data.content)
                    setLoading(false)
                }
            })

            .catch((err) => {
                setLoading(false)
                console.log(err)
                message.error("Servidor está fora")
            })

    }

    React.useEffect(() => {
        loadSinistro()
    }, [])

    return (
        <Template>
            <PageHeader
                className="site-page-header"
                title="Sinistro"
                // breadcrumb={{ routes }}
                subTitle="gerenciamento"
                style={{ paddingLeft: "0%" }}
            />

            {/* <Row gutter={[20, 20]}>
                <Col span={24} style={{ textAlign: "left" }}>
                    <Button
                        icon={<PlusSquareOutlined />}
                        onClick={() => setShowModal(true)}
                    >
                        Adicionar
                    </Button>
                </Col>
            </Row>
            <br /> */}

            <Row>
                <Col span={24}>
                    <Table
                        bordered
                        columns={columns}
                        dataSource={sinistro}
                        loading={loading}
                    />
                </Col>
            </Row>

            <Modal
                title="Registrar Sinistro"
                visible={showModal}
                onOk={closeModal}
                onCancel={closeModal}
                destroyOnClose={true}
                footer={false}
            >
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        name="seguros_id"
                        label="N# seguro"
                        rules={[
                            {
                                required: true,
                                message: "Campo nome obrigatório",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="descricao"
                        label="Descrição dos danos"
                        rules={[
                            {
                                required: true,
                                message: "Campo nome obrigatório",
                            },
                        ]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>

                    <Form.Item
                        name="orcamento"
                        label="Orçamento de Reparação"
                        rules={[
                            {
                                required: true,
                                message: "Campo nome obrigatório",
                            },
                        ]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                    <Form.Item name="franquia" label="">
                        <Checkbox check={check}>
                            Confirma se cobrou franquia de XXXX
                        </Checkbox>
                    </Form.Item>

                    <Form.Item
                        name="fotos"
                        label="Fotos do Item Avariado ou Doc:"
                        rules={[
                            {
                                required: true,
                                message: "Campo nome obrigatório",
                            },
                        ]}
                    >
                        <Upload
                            action={
                                config[process.env.NODE_ENV].url +
                                "/sinistro/upload"
                            }
                        >
                            <Button icon={<UploadOutlined />}>
                                Click para upload
                            </Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item style={{ textAlign: "left" }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loadSubmit}
                        >
                            Enviar
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="Visualização"
                visible={showModalS}
                onOk={() => setShowModalS(false)}
                onCancel={() => setShowModalS(false)}
                destroyOnClose={true}
                footer={false}
            >
                <p>
                    <b>Seguro:</b> {selectRecord.seguros_id}
                </p>
                <p>
                    <b>Descrição dos danos:</b> <br /> {selectRecord.descricao}
                </p>
                <p>
                    <b> Orçamento de Reparação:</b> <br />
                    {selectRecord.orcamento}
                </p>
                <p>
                    <b>Confirma se cobrou franquia de 20%:</b> <br />
                </p>

                <p>
                    <b>Arquivos</b> <br />

                    {foto.map((item) => (
                        <Card
                            hoverable
                            style={{ width: '100%' }}
                            cover={<img src={config[process.env.NODE_ENV].url + `/fotos/${item.arquivo}`} alt="foto" width="300" />}
                        >

                        </Card>

                    ))}
                </p>
            </Modal>
        </Template>
    )
}

export default Sinistro
