import React from "react";
import Template from "../../components/template";
import {
    Row,
    Col,
    PageHeader,
    Table,
    Tag,
    Button,
    message,
    Modal,
    Form,
    Input,
    Divider,
    Spin,
    Popconfirm,
    Select,
    DatePicker,
} from "antd";
import {
    DownloadOutlined,
    FormOutlined,
    MinusOutlined,
    PlusOutlined,
    SaveOutlined,
} from "@ant-design/icons";
import config from "../../config/config";
import { Link } from "react-router-dom";
import moment from "moment";
import { maskCurrency } from "../../components/utils";
import fileSaver from "file-saver";
moment.locale("pt");

const Pagamentos = () => {
    const [cliente, setCliente] = React.useState([]);
    const [seguro, setSeguro] = React.useState({});
    const [selected, setSelected] = React.useState(false);
    const [loading, setLoading] = React.useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const [loja, setLoja] = React.useState([]);
    const [excel, setExcel] = React.useState(false);

    const [form] = Form.useForm();
    const [form2] = Form.useForm();

    const { Option } = Select;
    const { TextArea } = Input;

    const { RangePicker } = DatePicker;

    const dataSeguros = async () => {
        const _initToken = await localStorage.getItem("authUserOptica");
        fetch(config[process.env.NODE_ENV].url + "/pagamento", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message);
                } else {
                    setCliente(data.content);
                    filterLoja(data.content);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                message.error("Servidor está fora");
            });
    };

    const columns = [
        {
            title: "#ID",
            dataIndex: "id",
            key: "id",
            width: 90,
        },
        {
            title: "Loja",
            dataIndex: "cooperador",
            key: "cooperador",
        },

        {
            title: "Nome",
            dataIndex: "nome",
            key: "nome",
        },

        {
            title: "Nº Fatura",
            dataIndex: "numero_fatura",
            key: "numero_fatura",
        },

        {
            title: "Valor do produto",
            dataIndex: "valor_prd",
            key: "valor_prd",
            width: 150,
            render: (valor) => {
                return maskCurrency(valor);
            },
        },
        {
            title: "Valor seguro",
            dataIndex: "valor_sgr",
            key: "valor_sgr",
            width: 150,
            render: (valor) => {
                return maskCurrency(valor);
            },
        },

        {
            title: "Valor Comissão",
            dataIndex: "valor_sgr",
            key: "valor_sgr",
            width: 150,
            render: (valor) => {
                //calcular 12.5% do valor do seguro
                const comissao = valor * 0.125;
                return maskCurrency(comissao);
            },
        },
        {
            title: "Pagamento Seguro",
            dataIndex: "pag_sgr",
            key: "pag_sgr",
            width: 150,
            render: (t) => {
                if (t == 1) {
                    return <Tag color="#87d068">Pago</Tag>;
                } else {
                    return <Tag color="#f50">Não Pago</Tag>;
                }
            },
        },

        {
            title: "Arquivos",
            dataIndex: "arquivo_rgpd",
            key: "arquivo_rgpd",
            ellipsis: true,
            render: (value) => {
                if (value != null) {
                    return (
                        <a
                            href={
                                config[process.env.NODE_ENV].url +
                                "/seguro/download/" +
                                value
                            }
                            target="_blank"
                            download
                            rel="noreferrer"
                        >
                            <Button
                                shape="circle"
                                icon={<DownloadOutlined />}
                            />
                        </a>
                    );
                }
            },
        },

        // {
        //     title: "Status Comprovativos",
        //     dataIndex: "status_comprovativos",
        //     key: "status_comprovativos",
        //     render: (v) => {
        //         switch (v) {
        //             case 1:
        //                 return <Tag color="#ffa500">Em analise</Tag>;
        //                 break;
        //             case 2:
        //                 return <Tag color="#87d068">Aprovado</Tag>;
        //                 break;

        //             case 3:
        //                 return <Tag color="#f50">Reprovado</Tag>;
        //                 break;
        //         }
        //     },
        // },

        {
            title: "Data de Registo",
            dataIndex: "data_registro",
            key: "data_registro",
            render: (v) => {
                return moment(v).format("DD/MM/YYYY");
            },
        },

        // {
        //     title: "Ações",
        //     dataIndex: "created_at",
        //     key: "created_at",
        //     width: 110,
        //     render: (value, record) => {
        //         return (
        //             <>
        //                 <Button
        //                     shape="circle"
        //                     icon={<FormOutlined />}
        //                     style={{ marginRight: "8px" }}
        //                     onClick={() => openModalUpdate(record)}
        //                 />
        //                 {/* <Popconfirm
        //                    title="Tem certeza que deseja deletar esta tarefa?"
        //                    okText="Sim"
        //                    cancelText="Não"
        //                    onConfirm={() => deleteLoja(record)}
        //                >
        //                    <Button
        //                        type="danger"
        //                        shape="circle"
        //                        icon={<MinusOutlined />}
        //                    />
        //                </Popconfirm> */}
        //             </>
        //         );
        //     },
        // },
    ];

    const filterLoja = (value) => {
        let filter = value.map((v) => {
            return {
                id: v.loja_id,
                loja: v.cooperador,
            };
        });
        filter = filter.filter((v, i, s) => {
            return s.findIndex((t) => t.id === v.id) === i;
        });
        setLoja(filter);
    };

    const openModalUpdate = (obj) => {
        setShowModal(true);
        setSeguro(obj);
        const selectObject = {
            1: "Em Analise",
            2: "Aprovado",
            3: "Reprovado",
        };
        if (obj.status_comprovativos === 3) {
            setSelected(true);
        } else {
            setSelected(false);
        }

        form.setFieldsValue({
            status_comprovativos: selectObject[obj.status_comprovativos],
            justificativa: obj.justificativa,
        });
    };

    const changeSelect = (v) => {
        if (v == 3) {
            setSelected(true);
        } else {
            setSelected(false);
        }
    };

    const submitFilter = (values) => {
        console.log(values);
        values.excel = false;
        setLoading(true);
        const _initToken = localStorage.getItem("authUserOptica");
        fetch(config[process.env.NODE_ENV].url + "/pagamento/filter", {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
            body: JSON.stringify(values),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message);
                } else {
                    message.loading({
                        content: "Carregando...",
                        key: "loading",
                    });

                    setTimeout(() => {
                        if (!excel) {
                            message.success({
                                content: "Pagamentos carregados com sucesso!",
                                key: "success",
                                duration: 2,
                            });
                            setCliente(data.content);
                        } else {
                            message.success({
                                content: "Relatório gerado com sucesso!",
                                key: "success",
                                duration: 2,
                            });
                            let buff = Buffer.from(JSON.stringify(data));
                            const blob = new Blob([buff], {
                                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                            });
                            fileSaver.saveAs(blob, "pagamentos.xlsx");
                        }

                        setLoading(false);
                    }, 1000);
                }
            })
            .catch((err) => {
                console.error(err);
                message.error("Servidor está fora");
                setLoading(false);
            });
    };

    const downloadExcel = async () => {
        setLoading(true);
        let values = form2.getFieldsValue();
        values.excel = true;
        const _initToken = localStorage.getItem("authUserOptica");
        fetch(config[process.env.NODE_ENV].url + "/pagamento/filter", {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "x-access-token": _initToken,
            }),
            body: JSON.stringify(values),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.hasOwnProperty("message")) {
                    message.error(data.message);
                } else {
                    message.loading({
                        content: "Carregando...",
                        key: "loading",
                    });

                    setTimeout(() => {
                        let tempLink = document.createElement("a");
                        tempLink.href =
                            config[process.env.NODE_ENV].url +
                            "/pagamento/relatorio";
                        tempLink.click();
                        setLoading(false);
                    }, 1000);
                }
            })
            .catch((err) => {
                console.error(err);
                message.error("Servidor está fora");
                setLoading(false);
            });
    };

    React.useEffect(() => {
        dataSeguros();
    }, []);

    return (
        <Template>
            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <PageHeader
                        className="site-page-header"
                        title="Pagamentos"
                        subTitle="lista"
                        style={{ paddingLeft: "0" }}
                    />
                </Col>
                <Col span={24}>
                    <Form form={form2} layout="inline" onFinish={submitFilter}>
                        <Col span={6}>
                            <Form.Item name="loja" label="Loja">
                                <Select
                                    style={{ width: "100%" }}
                                    allowClear={true}
                                >
                                    {loja.map((v, i) => (
                                        <Option value={v.id}>{v.loja}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="rangeDatas" label="Data">
                                <RangePicker
                                    allowClear={true}
                                    format="DD/MM/YYYY"
                                    placeholder={["Data inicial", "Data Final"]}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6} align="left">
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    onClick={() => {
                                        setExcel(false);
                                    }}
                                >
                                    Filtrar
                                </Button>
                            </Form.Item>
                        </Col>

                        <Col span={6} align="left">
                            <Button
                                type="primary"
                                onClick={() => {
                                    setExcel(true);
                                    downloadExcel();
                                }}
                            >
                                Download Excel
                            </Button>
                        </Col>
                    </Form>
                </Col>
                <Col span={24}>
                    <Table columns={columns} dataSource={cliente} />
                </Col>
            </Row>
            {/* FormEditavel */}
            <Modal
                title={"Seguro id:" + seguro.id}
                visible={showModal}
                onOk={() => setShowModal(false)}
                onCancel={() => setShowModal(false)}
                destroyOnClose={true}
                footer={false}
            >
                <Form form={form}>
                    <Row gutter={[20, 20]}>
                        <Col span={24}>
                            <Form.Item
                                name="status_comprovativos"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo stutus obrigatório!",
                                    },
                                ]}
                            >
                                <Select onChange={changeSelect}>
                                    <Option value="1">Em analise</Option>
                                    <Option value="2">Aprovado</Option>
                                    <Option value="3">Reprovado</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        {selected && (
                            <Col span={24}>
                                <Form.Item
                                    name="justificativa"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Campo justificativa obrigatório!",
                                        },
                                    ]}
                                >
                                    <TextArea />
                                </Form.Item>
                            </Col>
                        )}

                        <Col span={24}>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    icon={<SaveOutlined />}
                                    loading={loading}
                                    htmlType="submit"
                                    block
                                >
                                    Salvar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Template>
    );
};

export default Pagamentos;
