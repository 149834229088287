import React from "react"
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
    withRouter,
} from "react-router-dom"

import { createBrowserHistory } from "history"
import { isAuthenticated } from "../services/auth"
import Login from "../pages/auth/login"
import Dashboard from "../pages/dashboard"
import Usuarios from "../pages/usuarios"

// Novo Seguro

import NovoSeguro from "../pages/seguro"
import GerenciamentSeguro from "../pages/gerenciamento"
import Sinistro from "../pages/sinistro"
import Contato from "../pages/contato"
import Lojas from "../pages/lojas"
import Clientes from "../pages/clientes"
import Pagamentos from "../pages/pagamentos"
import Modalidades from "../pages/modalidades"
import Banner from "../pages/banner"

/** Browser History retrieve */
const customHistory = createBrowserHistory()
const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            isAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{ pathname: "/", state: { from: props.location } }}
                />
            )
        }
    />
)

const Routes = () => {
    return (
        <>
            <Router history={customHistory}>
                <Switch>
                    <Route exact path="/" component={Login} />
                    <PrivateRoute
                        exact
                        path="/dashboard"
                        component={Dashboard}
                    />
                    <PrivateRoute
                        exact
                        path="/novo-seguro"
                        component={NovoSeguro}
                    />
                    <PrivateRoute
                        exact
                        path="/gerenciar"
                        component={GerenciamentSeguro}
                    />
                    <PrivateRoute exact path="/usuarios" component={Usuarios} />
                    <PrivateRoute exact path="/sinistro" component={Sinistro} />
                    <PrivateRoute exact path="/contato" component={Contato} />
                    <PrivateRoute exact path="/lojas" component={Lojas} />
                    <PrivateRoute exact path="/clientes" component={Clientes} />
                    <PrivateRoute exact path="/pagamentos" component={Pagamentos} />
                    <PrivateRoute exact path="/modalidades" component={Modalidades} />
                    <PrivateRoute exact path="/banner" component={Banner} />
                </Switch>
            </Router>
        </>
    )
}
export default Routes
