import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";

export const CustomBreadCrumb = (props) => {
    console.log("aqui", props);
    const renderData = props.data.map((v, i) => {
        return (
            <Breadcrumb.Item key={i} href={v.link}>
                {v.icon}
                <span>{v.nome}</span>
            </Breadcrumb.Item>
        );
    });

    return <Breadcrumb>{renderData}</Breadcrumb>;
};
